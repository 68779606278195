@import url('https://fonts.googleapis.com/css2?family=Hanken+Grotesk:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Hanken Grotesk', sans-serif !important;
}

html {
  scroll-behavior: smooth;
}

::selection {
  display: none;
}

body {
  overflow-x: hidden !important;
  width: 100vw !important;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

::-webkit-scrollbar:horizontal {
  display: none !important;
  visibility: hidden !important;
}

.animation {
  animation: 2s anim-lineUp ease-out;
}

@keyframes anim-lineUp {
  0% {
    opacity: 0;
    transform: translateY(80%);
  }

  20% {
    opacity: 0;
  }

  50% {
    opacity: 1;
    transform: translateY(0%);
  }

  100% {
    opacity: 1;
    transform: translateY(0%);
  }
}

@media screen and (max-width: 800px) {
  body {
    overflow-x: hidden !important;
    width: 100vw !important;
    overflow-x: none !important;
  }

  html {
    overflow-x: hidden !important;
    overflow-x: none !important;
  }

  element::-webkit-scrollbar {
    display: none !important;
  }
}