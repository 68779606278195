.nf-hero {
    width: 100%;
    height: 70vh;
    position: relative;
    text-align: center;
    background-image: none !important;
}

.nf-hero-section {
    max-width: 1200px;
    margin: auto;
    position: relative;
    height: inherit;
    display: flex;
    align-items: center;
    padding-bottom: 25px;
    justify-content: center;
}

.nf-hero-image {
    width: 100%;
    height: 70vh;
    object-fit: cover;
    object-position: center;
    z-index: -111 !important;
    position: absolute;
    top: 0;
    left: 0;
}

.nf-hero-title {
    color: white;
    text-transform: none;
    font-size: 3.5rem;
}

.animation {
    animation: 3.5s anim-lineUp ease-out;
}

@keyframes anim-lineUp {
    0% {
        opacity: 0;
        transform: translateY(80%);
    }

    20% {
        opacity: 0;
    }

    50% {
        opacity: 1;
        transform: translateY(0%);
    }

    100% {
        opacity: 1;
        transform: translateY(0%);
    }
}

.nf-hero-paragraph {
    color: rgb(241, 241, 241);
    margin-top: 15px;
    font-size: 1.2rem;
}

.nf-back-button {
    color: white;
    background-color: transparent;
    border: 1px solid white;
    text-decoration: none;
    position: relative;
    top: 50px;
    padding: 10px 20px;
    font-size: 1.2rem;
    transition: .25s;
    text-shadow: 0 0 5px rgba(0, 0, 0, 0.4);
}

.nf-back-button:hover {
    color: white;
    background-color: #99cc00;
    border: 1px solid #99cc00;
}

@media screen and (max-width: 800px) {

    .nf-hero-section {
        text-align: center;
    }

    .nf-hero-title {
        font-size: 3rem;
        text-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    }

    .nf-hero-paragraph {
        font-size: 16px;
        text-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    }
}

@media screen and (max-width: 1250px) {
    .nf-hero-section {
        padding: 50px;
    }
}