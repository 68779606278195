@import url('https://fonts.googleapis.com/css2?family=Hanken+Grotesk:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

:root {
    --swiper-theme-color: #84af01 !important;
}

* {
    color: #1f1f1f;
}

.pac-section {
    max-width: 1200px;
    width: 100%;
    margin: 0 auto 175px;
    display: flex;
    align-items: flex-start;
    justify-content: space-around;
    padding: 75px 0;
}

.principles-section {
    width: 50%;
}

.principles-section h1 {
    font-weight: 900;
    font-size: 40px;
    margin: 0 0 50px;
}

.principles-section h1 span {
    background-image: linear-gradient(to top, #99cc00 50%, transparent 50%);
    padding: 0 10px;
}

.principles {
    display: flex;
    margin: 27px 0;
    align-items: center;
}

.principles h4 {
    background-color: #1f1f1f;
    width: 45px;
    height: 45px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    color: white;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
    margin-right: 15px;
    font-size: 18px;
}

.principles p {
    font-size: 18px;
}

.clients-section {
    width: 50%;
    cursor: grab;
}

.clients-section h1 {
    font-weight: 900;
    font-size: 40px;
    margin: 0 0 50px;
}

.clients-section h1 span {
    background-image: linear-gradient(to top, #99cc00 50%, transparent 50%);
    padding: 0 10px;
}

.client-square {
    flex: 1 0 45%;
    background-color: #99cc0036;
    border-radius: 10px;
    padding: 20px;
    height: 350px;
    position: relative;
}

.client-square h3 {
    font-size: 30px;
    font-weight: 800;
}

.client-square p {
    font-size: 17px;
    line-height: 24px;
    margin: 15px 0;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 85%;
}

.client-square h5 {
    position: absolute;
    bottom: 20px;
    right: 20px;
    font-weight: 500;
    font-size: 14px;
}

@media screen and (max-width: 800px) {

    .pac-section {
        display: block;
        padding: 50px 0;
        width: 85%;
        position: relative;
        top: 100px;
    }

    .principles-section {
        width: 100%;
    }

    .clients-section{
        width: 100%;
        margin: auto;
        margin-top: 125px;
    }
}

@media screen and (min-width: 800px) and (max-width: 1250px) {
    .pac-section {
        padding: 0 50px;
    }

    .principles-section {
        width: 45%;
    }

    .clients-section {
        width: 50%;
    }
}