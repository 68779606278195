@import url('https://fonts.googleapis.com/css2?family=Hanken+Grotesk:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

.jt-section {
    max-width: 1200px;
    margin: 175px auto;
}

.jt-title-section {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 0 50px 0;
}

.jt-title h1 {
    font-weight: 900;
    font-size: 36px;
    margin-bottom: 10px;
}

.jt-title p {
    font-size: 18px;
}

.jt-link a {
    color: white;
    text-decoration: none;
    padding: 10px 20px;
    border: 1px solid #99cc00;
    background-color: #99cc00;
    font-size: 18px;
    font-weight: 700;
    text-transform: uppercase;
    transition: .25s;
}

.jt-link a:hover {
    background-color: white;
    color: #99cc00;
    box-shadow: 0 0 10px #99cc001e;
}

.jt-image img {
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
}

.jt-jobs {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: space-between;
    margin-top: 50px;
}

.jt-jobs .jobs {
    width: 20%;
}

.jt-jobs .jobs h1 {
    font-size: 26px;
    font-weight: 700;
    border-left: 5px solid #99cc00;
    padding-left: 10px;
}

.jt-jobs .jobs p {
    margin: 25px 0;
}

.jt-jobs .jobs a {
    color: #99cc00;
    text-decoration: none;
    border: 1px solid #99cc00;
    background-color: white;
    padding: 5px 10px;
    transition: .25s;
}

.jt-jobs .jobs a:hover {
    color: white;
    background-color: #99cc00;
}

@media screen and (max-width: 800px) {
    .jt-section {
        width: 85%;
        margin: auto;
    }

    .jt-title p {
        font-size: 16px;
        width: 75%;
    }

    .jt-link a {
        font-size: 16px;
    }

    .jt-image img {
        width: 100%;
        border-radius: 0;
    }

    .jt-jobs .jobs {
        flex: 50%;
        padding: 50px 0;
    }

    .jt-jobs .jobs:nth-child(4) {
        padding-bottom: 0;
    }

    .jt-jobs .jobs:nth-child(3) {
        padding-bottom: 0;
    }

    .jt-jobs .jobs p {
        width: 85%;
    }
}

@media screen and (min-width: 800px) and (max-width: 1250px) {
    .jt-section {
        margin: 175px 50px;
    }

    .jt-title-section {
        width: 100%;
    }

    .jt-image img {
        width: 100%;
    }
}