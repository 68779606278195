.r-hero {
    width: 100%;
    height: 70vh;
    position: relative;
    text-align: center;
    background-image: none !important;
}

.r-hero-section {
    max-width: 1200px;
    margin: auto;
    text-align: center;
    position: relative;
    height: inherit;
    display: flex;
    align-items: flex-end;
    padding-bottom: 25px;
}

.r-hero-image {
    width: 100%;
    height: 70vh;
    object-fit: cover;
    object-position: bottom;
    z-index: -111 !important;
    position: absolute;
    top: 0;
    left: 0;
}

.r-hero-title {
    color: white;
    text-transform: none;
    text-align: left;
    font-size: 2.5rem;
}

.animation {
    animation: 3.5s anim-lineUp ease-out;
}

@keyframes anim-lineUp {
    0% {
        opacity: 0;
        transform: translateY(80%);
    }

    20% {
        opacity: 0;
    }

    50% {
        opacity: 1;
        transform: translateY(0%);
    }

    100% {
        opacity: 1;
        transform: translateY(0%);
    }
}

.r-hero-paragraph {
    color: rgb(241, 241, 241);
    float: left;
    margin-top: 15px;
    font-size: 1.2rem;
}

@media screen and (max-width: 800px) {

    .r-hero-section {
        text-align: center;
    }

    .r-hero-title {
        font-size: 3rem;
        text-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    }

    .r-hero-paragraph {
        font-size: 16px;
        text-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    }
}

@media screen and (max-width: 1300px) {
    .r-hero-section {
        padding: 50px;
    }
}