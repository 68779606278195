@import url('https://fonts.googleapis.com/css2?family=Hanken+Grotesk:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

.aw-section {
    max-width: 1200px;
    margin: 175px auto;
}

.aw-section h1 {
    font-weight: 900;
    font-size: 36px;
    position: relative;
    margin-bottom: 50px;
}

.aw-section h1::after {
    content: '';
    position: absolute;
    width: 100px;
    height: 5px;
    background: #99cc00;
    bottom: -20px;
    left: 0;
    border-radius: 10px;
}

.aw-questions {
    margin-top: 75px;
    background-color: #f5f5f5;
    padding: 25px;
    border-radius: 8px;
}

.aw-section p {
    font-size: 18px;
    line-height: 26px;
    width: 60%;
}

.mt {
    margin-top: 50px;
}

.aw-question-radio p {
    margin: 0 0 5px 5px;
}

[type=radio]:checked,
[type=radio]:not(:checked) {
    position: absolute;
    left: -9999px;
}

[type=radio]:checked+label,
[type=radio]:not(:checked)+label {
    position: relative;
    padding-left: 25px;
    cursor: pointer;
    line-height: 20px;
    display: inline-block;
    font-size: 17px;
    font-weight: 400;
}

[type=radio]:checked+label:before,
[type=radio]:not(:checked)+label:before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 18px;
    height: 18px;
    border: 1px solid #999999;
    border-radius: 50%;
    background: #fff;
}

[type=radio]:checked+label:after,
[type=radio]:not(:checked)+label:after {
    content: "";
    width: 12px;
    height: 12px;
    background: #99cc00;
    position: absolute;
    top: 4px;
    left: 4px;
    border-radius: 50%;
    transition: all 0.2s ease;
}

[type=radio]:not(:checked)+label:after {
    opacity: 0;
    transform: scale(0);
}

[type=radio]:checked+label:after {
    opacity: 1;
    transform: scale(1);
}

.aw-question-radio h3 {
    font-size: 20px;
    margin-bottom: 15px;
    font-weight: 600;
}

.aw-question-input {
    margin-top: 50px;
}

.aw-question-input label {
    font-size: 20px;
    font-weight: 600;
    display: block;
    margin-bottom: 15px;
}

.aw-question-input input {
    width: 100%;
    border: none;
    border-bottom: 1px solid lightgray;
    background: transparent;
    padding: 5px 10px;
    font-size: 17px;
    transition: .25s;
}

.aw-question-input input:focus {
    border-bottom: 1px solid #99cc00;
}

.aw-question-input textarea {
    width: 100%;
    border: none;
    border-bottom: 1px solid lightgray;
    background: transparent;
    padding: 5px 10px;
    font-size: 17px;
    transition: .25s;
    text-transform: capitalize;
    resize: none;
}

.aw-question-input textarea:focus {
    border-bottom: 1px solid #99cc00;
}

.aw-questions>form>button {
    background-color: #99cc00;
    height: 40px;
    width: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 50px;
    text-shadow: 0 0 5px rgba(0, 0, 0, .2);
    transition: .25s;
}

.aw-questions>form>button:hover {
    background-color: #7ea801;
}

.kolonat {
    color: rgb(255, 94, 94);
    font-size: 10px;
    margin-bottom: 25px;
}

.form-group {
    display: block;
    margin-bottom: 15px;
}

.form-group input {
    padding: 0;
    height: initial;
    width: initial;
    margin-bottom: 0;
    display: none;
    cursor: pointer;
}

.form-group label {
    position: relative;
    cursor: pointer;
}

.form-group label:before {
    content: '';
    -webkit-appearance: none;
    background-color: transparent;
    border: 1px solid #1f1f1f;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
    padding: 10px;
    display: inline-block;
    position: relative;
    vertical-align: middle;
    cursor: pointer;
    margin-right: 10px;
    border-radius: 4px;
}

.form-group input:checked+label:after {
    content: '';
    display: block;
    position: absolute;
    top: 3px;
    left: 8px;
    width: 5px;
    height: 12px;
    border: solid #1f1f1f;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
}

@media screen and (max-width: 800px) {
    .aw-section {
        display: block;
        margin: 100px auto;
    }

    .aw-section h1 {
        width: 85%;
        margin: auto;
    }

    .aw-section p {
        width: 85%;
        margin: 75px auto 0;
    }

    .aw-questions {
        width: 100%;
    }

    .aw-questions>form {
        width: 85%;
        margin: auto;
    }

    .kolonat {
        text-align: center;
    }

    .sm-tp {
        margin-top: 50px;
    }

    .aw-question-radio h3 {
        margin-bottom: -50px;
    }

    .aw-question-radio p {
        margin-bottom: -50px;
    }

    .aw-question-radio p:nth-last-child(1) {
        margin-bottom: 50px;
    }

    .patent-h3{
        margin-bottom: 50px !important;
    }
}


@media screen and (min-width: 800px) and (max-width: 1250px) {
    .aw-section {
        padding: 0 50px;
    }
}