@import url('https://fonts.googleapis.com/css2?family=Hanken+Grotesk:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

* {
    color: #1f1f1f;
}

.fleet-background{
    background-color: #99cc000a;
    width: 100%;
}

.fleet-section {
    max-width: 1200px;
    width: 100%;
    margin: 175px auto;
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding: 75px 0;
}

.f-img-div {
    width: 48%;
    margin-right: 0;
}

.f-img-div img {
    width: 98%;
    margin: auto;
    float: right;
    border-radius: 10px;
}

.f-paragraph-div {
    width: 40%;
}

.f-paragraph-div h4{
    text-transform: uppercase;
    color: #969696;
    font-weight: 500;
}

.f-paragraph-div h1 {
    font-weight: 900;
    font-size: 46px;
    position: relative;
    margin: 10px 0 50px;
}

.f-paragraph-div h1 span{
    background-image: linear-gradient(to top, #99cc00 50%, transparent 50%);
    padding: 0 10px;
}

.f-paragraph-div p{
    font-size: 18px;
    line-height: 26px;
}

@media screen and (max-width: 800px) {
    .fleet-background{
        padding: 0;
    }

    .fleet-section {
        display: block;
        padding: 50px 0;
    }

    .f-img-div {
        margin: auto;
        width: 85%;
    }

    .f-img-div img{
        width: 100%;
        margin-top: 75px;
    }

    .f-paragraph-div {
        margin: 50px auto 0;
        width: 85%;
    }

    .f-paragraph-div h1{
        font-size: 40px;
    }
}

@media screen and (min-width: 800px) and (max-width: 1250px){
    .fleet-section{
        padding: 0 50px;
    }

    .f-img-div{
        width: 45%;
    }

    .f-paragraph-div{
        width: 45%;
    }
}