@import url('https://fonts.googleapis.com/css2?family=Hanken+Grotesk:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

.gs-section {
    max-width: 1200px;
    width: 100%;
    margin: 175px auto;
}

.gs-section h1 {
    font-weight: 900;
    font-size: 36px;
    position: relative;
    margin-bottom: 50px;
    text-align: center;
}

.gs-section h1::after {
    content: '';
    position: absolute;
    width: 100px;
    height: 5px;
    background: #99cc00;
    bottom: -20px;
    left: 50%;
    transform: translateX(-50%);
    border-radius: 10px;
}

.gs-section p {
    width: 50%;
    margin: auto;
    text-align: justify;
    font-size: 18px;
    line-height: 28px;
}

@media screen and (max-width: 800px) {
    .gs-section {
        display: block;
        margin: 125px auto;
        width: 85%;
    }

    .gs-section h1{
        width: 100%;
    }

    .gs-section p{
        width: 100%;
        position: relative;
        top: 25px;
    }
}


@media screen and (min-width: 800px) and (max-width: 1250px) {
    .gs-section {
        padding: 0 50px;
    }

    .gs-section h1 {
        width: 100%;
    }

    .gs-section p {
        width: 75%;
    }
}