.loading-page {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 100vh;
}

.loading-page img {
    filter: grayscale(100%);
    animation: bw-animation 2500ms;
    animation-fill-mode: forwards;
    width: 10rem;
}

@keyframes bw-animation {
    0% {
        filter: grayscale(100%);
        transform: scale(1);
    }

    100% {
        filter: grayscale(0%);
        transform: scale(1.05);
    }
}