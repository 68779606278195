@import url('https://fonts.googleapis.com/css2?family=Hanken+Grotesk:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

.ct-section {
    max-width: 1200px;
    margin: 175px auto;
    height: 350px;
    background-image: url("./Background.webp");
    background-size: cover;
    background-repeat: no-repeat;
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 50px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
}

.ct-image-section {
    width: 50%;
}

.ct-image-section img {
    width: 100%;
}

.ct-text-section {
    width: 50%;
    text-align: right;
}

.ct-text-section h1 {
    color: white;
    font-weight: 900;
    text-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    font-size: 45px;
}

.ct-text-section p {
    color: rgb(250, 250, 250);
    margin: 25px 0 35px;
    font-size: 20px;
}

.ct-text-section a {
    color: white;
    border: 1px solid white;
    padding: 8px 20px;
    text-decoration: none;
    transition: .25s;
}

.ct-text-section a:hover {
    color: #99cc00;
    border: 1px solid white;
    background-color: white;
}

@media screen and (max-width: 800px) {
    .ct-section {
        display: block;
        height: auto;
        background-position: left;
        width: 85%;
    }

    .ct-image-section {
        width: 100%;
    }

    .ct-text-section {
        width: 100%;
        text-align: center;
        margin-top: 25px;
    }

    .ct-text-section h1 {
        font-size: 36px;
    }

    .ct-text-section p {
        font-size: 16px;
    }

    .ct-text-section a {
        padding: 10px 20px;
        font-size: 18px;
    }

    .ct-text-section a:hover {
        color: #333399;
    }
}

@media screen and (min-width: 800px) and (max-width: 1250px) {
    .ct-section {
        margin: 0 50px;
    }

    .img-div {
        width: 45%;
    }

    .paragraph-div {
        width: 45%;
    }
}