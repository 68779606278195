@import url('https://fonts.googleapis.com/css2?family=Hanken+Grotesk:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

* {
    color: #1f1f1f;
}

.home-section {
    max-width: 1200px;
    width: 100%;
    margin: 175px auto;
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.img-div {
    width: 48%;
    margin: auto;
}

.img-div img {
    width: 90%;
    margin: auto;
}

.paragraph-div {
    width: 48%;
}

.paragraph-div a {
    --color: #1f1f1f;
    position: relative;
    text-decoration: none;
    padding: 0.2rem 0;
    top: 25px;
    font-size: 16px !important;
}

.paragraph-div a::before {
    --line-width: 115%;
    --line-height: 1px;
    --line-easing: ease;
    --line-transition-duration: 300ms;
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    width: var(--line-width);
    height: var(--line-height);
    transform-origin: right;
    transform: scaleX(0);
    background: var(--color);
    transition: transform var(--line-transition-duration) var(--line-easing);
    z-index: 1;
}

.paragraph-div a:hover::before {
    transform-origin: left;
    transform: scaleX(1);
}

.paragraph-div a:hover span {
    --deg: -45deg;
}

.paragraph-div a:hover span::before {
    transform: rotate(var(--deg));
}

.paragraph-div a:hover span::after {
    transform: translateX(-1px) rotate(var(--deg));
}

.paragraph-div a span {
    --line-arrow-width: 1px;
    --line-arrow-height: 6px;
    --line-arrow-easing: cubic-bezier(0.3, 1.5, 0.5, 1);
    --line-arrow-transition-duration: 200ms;
    --line-arrow-transition-delay: 240ms;
}

.paragraph-div a span::before,
.paragraph-div a span::after {
    content: "";
    position: absolute;
    right: -18%;
    bottom: 0px;
    background: var(--color);
    transition: transform var(--line-arrow-transition-duration) var(--line-arrow-easing);
    transition-delay: var(--line-arrow-transition-delay);
    z-index: 2;
}

.paragraph-div a span::before {
    width: var(--line-arrow-width);
    height: var(--line-arrow-height);
    transform-origin: 0% 100%;
    transform: rotate(-90deg);
}

.paragraph-div a span::after {
    height: var(--line-arrow-width);
    width: var(--line-arrow-height);
    transform-origin: 100% 0%;
    transform: translateX(-1px) rotate(0deg);
}

.paragraph-div h1 {
    font-weight: 900;
    font-size: 36px;
    position: relative;
    margin-bottom: 50px;
}

.paragraph-div h1::after {
    content: '';
    position: absolute;
    width: 100px;
    height: 3px;
    background: #99cc00;
    bottom: 0;
    left: 0;
}

.services-div {
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin: 25px 0;
}

.services-div img {
    width: 80px;
    margin-right: 25px;
}

.services-div p {
    font-size: 16px;
    line-height: 22px;
}

@media screen and (max-width: 800px) {
    .home-section {
        display: block;
    }

    .img-div {
        margin: auto;
        width: 85%;
    }

    .img-div img{
        width: 100%;
    }

    .paragraph-div {
        margin: 50px auto 0;
        width: 85%;
    }
}

@media screen and (min-width: 800px) and (max-width: 1250px){
    .home-section{
        padding: 0 50px;
    }

    .img-div{
        width: 45%;
    }

    .paragraph-div{
        width: 45%;
    }
}