@import url('https://fonts.googleapis.com/css2?family=Hanken+Grotesk:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

.about-section {
    max-width: 1200px !important;
    margin: 175px auto;
}

.section-1 {
    display: flex;
    align-items: center;
    justify-content: space-around;
    width: 100%;
}

.title-div {
    width: 48%;
}

.title-div h1 {
    font-weight: 900;
    font-size: 36px;
    position: relative;
    margin-bottom: 25px;
}

.title-div h1::after {
    content: '';
    position: absolute;
    width: 100px;
    height: 3px;
    background: #99cc00;
    bottom: 0;
    left: 0;
}

.title-div p {
    font-size: 16px;
    margin: 50px 0 25px;
    line-height: 22px;
    width: 80%;
}

.title-div a {
    --color: #1f1f1f;
    position: relative;
    text-decoration: none;
    padding: 0.2rem 0;
    top: 25px;
    font-size: 16px !important;
}

.title-div a::before {
    --line-width: 115%;
    --line-height: 1px;
    --line-easing: ease;
    --line-transition-duration: 300ms;
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    width: var(--line-width);
    height: var(--line-height);
    transform-origin: right;
    transform: scaleX(0);
    background: var(--color);
    transition: transform var(--line-transition-duration) var(--line-easing);
    z-index: 1;
}

.title-div a:hover::before {
    transform-origin: left;
    transform: scaleX(1);
}

.title-div a:hover span {
    --deg: -45deg;
}

.title-div a:hover span::before {
    transform: rotate(var(--deg));
}

.title-div a:hover span::after {
    transform: translateX(-1px) rotate(var(--deg));
}

.title-div a span {
    --line-arrow-width: 1px;
    --line-arrow-height: 6px;
    --line-arrow-easing: cubic-bezier(0.3, 1.5, 0.5, 1);
    --line-arrow-transition-duration: 200ms;
    --line-arrow-transition-delay: 240ms;
}

.title-div a span::before,
.title-div a span::after {
    content: "";
    position: absolute;
    right: -18%;
    bottom: 0px;
    background: var(--color);
    transition: transform var(--line-arrow-transition-duration) var(--line-arrow-easing);
    transition-delay: var(--line-arrow-transition-delay);
    z-index: 2;
}

.title-div a span::before {
    width: var(--line-arrow-width);
    height: var(--line-arrow-height);
    transform-origin: 0% 100%;
    transform: rotate(-90deg);
}

.title-div a span::after {
    height: var(--line-arrow-width);
    width: var(--line-arrow-height);
    transform-origin: 100% 0%;
    transform: translateX(-1px) rotate(0deg);
}

.image-section {
    width: 48%;
    position: relative;
    overflow: hidden;
    border-radius: 10px;
}

.image-section img {
    width: 100%;
    border-radius: 10px;
    transition: transform 200ms;
}

.image-section:hover img {
    transform: scale(1.1);
}

.image-section h1 {
    color: white;
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    padding: 25px;
    background-image: linear-gradient(to top, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0.55), rgba(0, 0, 0, 0.01));
    border-radius: 0 0 10px 10px;
    font-size: 25px;
}

.section-2 {
    margin-top: 100px;
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.image-1-section {
    width: 48%;
    position: relative;
    overflow: hidden;
    border-radius: 10px;
}

.image-1-section img {
    width: 100%;
    border-radius: 10px;
    transition: transform 200ms;
}

.image-1-section:hover img {
    transform: scale(1.1);
}

.image-1-section h1 {
    color: white;
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    padding: 25px;
    background-image: linear-gradient(to top, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0.55), rgba(0, 0, 0, 0.01));
    border-radius: 0 0 10px 10px;
    font-size: 25px;
}

.image-2-section {
    width: 48%;
    position: relative;
    overflow: hidden;
    border-radius: 10px;
}

.image-2-section img {
    width: 100%;
    border-radius: 10px;
    transition: transform 200ms;
}

.image-2-section:hover img {
    transform: scale(1.1);
}

.image-2-section h1 {
    color: white;
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    padding: 25px;
    background-image: linear-gradient(to top, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0.55), rgba(0, 0, 0, 0.01));
    border-radius: 0 0 10px 10px;
    font-size: 25px;
}

@media screen and (max-width: 800px){

    .section-1{
        display: inline-block;
    }

    .title-div{
        display: block;
        width: 85%;
        margin: auto;
    }

    .title-div p{
        width: 100%;
    }

    .image-section{
        display: block;
        width: 85%;
        margin: 100px auto 0px;
    }

    .section-2{
        display: block;
        margin: 50px auto;
    }

    .image-1-section{
        display: block;
        width: 85%;
        margin: auto;
    }

    .image-2-section{
        display: block;
        width: 85%;
        margin: 50px auto;
    }

    .image-section h1{
        font-size: 20px;
    }

    .image-1-section h1{
        font-size: 20px;
    }

    .image-2-section h1{
        font-size: 20px;
    }
}

@media screen and (min-width: 800px) and (max-width: 1250px){
    .about-section{
        padding: 0 50px;
    }
}