* {
    outline: none !important;
}

.g-section {
    max-width: 1200px;
    margin: 175px auto;
}

.g-section h1 {
    font-weight: 900;
    font-size: 36px;
    position: relative;
    margin-bottom: 25px;
}

.g-section h1::after {
    content: '';
    position: absolute;
    width: 100px;
    height: 3px;
    background: #99cc00;
    bottom: 0;
    left: 0;
}

.g-slider img {
    border-radius: 10px;
    cursor: grab;
    height: 500px;
}

@media screen and (max-width: 800px) {
    .g-section {
        width: 85%;
        margin: normal auto;
    }

    .g-slider img {
        width: 100%;
        height: 300px;
        object-fit: cover;
    }
}

@media screen and (min-width: 800px) and (max-width: 1250px) {
    .g-section {
        margin: 175px 50px;
    }

    .g-slider img {
        width: 100%;
        height: 350px;
    }
}