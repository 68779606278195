button {
    border: 0;
    padding: 0;
    background: transparent;
    cursor: pointer;
    line-height: 3.5;
    font-size: 20px;
    color: white;
}

@media (width >=800px) {
    button {
        font-size: 17px;
        font-weight: 600;
        letter-spacing: .6px;
    }

    .navbar {
        max-width: 1250px;
        padding: 15px;
        position: fixed;
        left: 50% !important;
        transform: translateX(-50%) !important;
        height: 72px;
    }
}

:is(.navbar, .burger, .links) {
    position: fixed;
    top: 0;
    width: 100%;
}

.navbar {
    z-index: 99999 !important;
    left: 0;
    display: flex;
    align-items: stretch;
    height: 72px;
    background: transparent;
    color: white;
    position: fixed !important;
    transition: .25s;
}

.burger {
    z-index: 3;
    right: 0;
    display: grid;
    place-items: center;
    width: 72px;
    height: 72px;
    background-image: url("menu.svg");
    background-repeat: no-repeat;
    background-position: center;
}

body.open .burger {
    background-image: url("close.svg");
}

body.open .burger:focus {
    outline: none !important;
}

@media (width >=800px) {
    .burger {
        display: none;
    }
}

.button {
    display: flex;
    align-items: center;
    gap: 4px;
    padding: 30px 24px;
    height: 100%;
    transition: .25s;
    text-decoration: none;
    color: white;
    font-weight: 700;
    letter-spacing: 1px;
    font-size: 24px;
    text-shadow: 0 0 5px rgba(0, 0, 0, 0.4);
}

.button>img {
    display: none;
}

@media (width >=800px) {
    .button {
        padding: 0 10px 0 24px;
        font-size: 18px;
    }

    .button>img {
        display: block;
    }
}

@media (width >=800px) {
    .link:hover .button {
        opacity: 1;
    }
}

.links {
    left: -9999px;
    z-index: 2;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: rgba(0, 0, 0, 0.8);
    backdrop-filter: blur(5px);
    opacity: 0;
    visibility: hidden;
    transition-property: opacity, visibility;
    transition-duration: 0.3s;
}

@media (width < 800px) {
    body.open .links {
        opacity: 1;
        visibility: visible;
        left: 0;
    }
}

@media (width >=800px) {
    .links {
        position: static;
        flex-direction: row;
        justify-content: flex-start;
        background: transparent;
        opacity: 1;
        visibility: visible;
        backdrop-filter: blur(0px);
    }
}

.link {
    position: relative;
    display: flex;
    align-items: center;
    flex-direction: column;
}

.link:hover button{
    color: #99cc00 !important;
}

.link-reserve:hover button{
    color: #99cc00 !important;
}

.link-menu {
    display: grid;
    margin-bottom: 28px;
}

@media (width >=800px) {
    .link {
        height: 100%;
        flex-direction: row;
    }

    .link-menu {
        position: absolute;
        top: 72px;
        left: 0;
        width: 180px;
        padding: 6px 24px 10px;
        margin-bottom: 0;
        place-items: start;
        background: #19191c;
        opacity: 0;
        visibility: hidden;
        translate: 0 24px;
        transition: 0.3s;
    }
}

.link-menu>button {
    color: #f9f9f9;
    opacity: 1;
    height: 40px;
}

@media (width >=800px) {
    .link-menu>button {
        opacity: 0.6;
    }

    .link:hover .button {
        opacity: 1;
        color: #99cc00;
    }

    .link-reserve {
        float: right !important;
        position: absolute;
        right: 30px;
    }

    .link-reserve .button{
        text-shadow: 0 0 10px rgba(0, 0, 0, 0.8) !important;
    }

    .link-reserve:hover button{
        color: #99cc00;
    }

    .logo {
        padding: 0 !important;
        margin-right: 25px;
    }
}

.link-menu>button:hover {
    opacity: 1;
    color: #99cc00 !important;
}

.logo {
    padding: 15px;
    z-index: 9999 !important;
    width: 9rem !important;
}

.nav-blur {
    position: absolute;
    left: 0 !important;
    right: 0;
    top: 0;
    height: 72px;
    z-index: -1;
}

.active{
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0) 90%);
    transition-duration: .25s;
}

.reserve:hover{
    color: #99cc00;
}