.home-hero {
    width: 100%;
    height: 100vh;
    position: relative;
    text-align: center;
    background-image: none !important;
}

.eco{
    background-color: #99cc00;
    padding: 0px 15px;
    color: white;
    box-shadow: 0 0 25px #99cc00;
}

.hero-image {
    width: 100%;
    height: 100vh;
    object-fit: cover;
    object-position: bottom;
    z-index: -111 !important;
    position: absolute;
    top: 0;
    left: 0;
}

.home-hero-section {
    max-width: 1200px;
    margin: auto;
    text-align: center;
    position: relative;
    height: inherit;
    display: flex;
    align-items: center;
    z-index: 10 !important;
}

.home-hero-title {
    color: white;
    /* position: absolute;
    left: 0;
    top: 50%;
    transform: translate(0, -50%); */
    text-transform: uppercase;
    text-align: left;
    font-size: 5rem;
}

.cl-section {
    position: absolute;
    bottom: 25px;
    right: 50px;
    z-index: 999999 !important;
}

.cl-section a {
    margin: auto;
    color: white;
    text-decoration: none;
    margin: 0 5px;
    font-size: 20px;
}

.sq {
    opacity: 1 !important;
}

.en:hover {
    opacity: 1 !important;
}

.en {
    opacity: .6 !important;
    transition: .25s;
}

.animation {
    animation: 3.5s anim-lineUp ease-out !important;
}

@keyframes anim-lineUp {
    0% {
        opacity: 0;
        transform: translateY(80%);
    }

    20% {
        opacity: 0;
    }

    50% {
        opacity: 1;
        transform: translateY(0%);
    }

    100% {
        opacity: 1;
        transform: translateY(0%);
    }
}

.home-hero-link {
    color: white;
    float: left;
    text-decoration: none;
    margin-top: 25px;
    font-size: 20px;
    border: 1px solid white;
    padding: 7px 15px;
    transition: .25s;
}

.home-hero-link:hover {
    color: white;
    border: 1px solid #99cc00;
    background-color: #99cc00;
}

.arrowCta {
    display: block;
    height: 15px;
    width: 15px;
    border: 11px solid transparent;
    transform: rotate(45deg);
    position: absolute;
    bottom: 50px;
    cursor: pointer;
}

.arrowCta:after,
.arrowCta:before {
    content: "";
    display: block;
    height: inherit;
    width: inherit;
    position: absolute;
    top: 0;
    left: 0;
}

.arrowCta:after {
    border-bottom: 3px solid white;
    border-right: 3px solid white;
    top: 0;
    left: 0;
    opacity: 1;
    animation: bottom-arrow 1.65s infinite;
}

@keyframes bottom-arrow {
    0% {
        opacity: 1;
        transform: translate(0, 0);
    }

    45% {
        opacity: 0;
        transform: translate(12px, 12px);
    }

    46% {
        opacity: 0;
        transform: translate(-16px, -16px);
    }

    90% {
        opacity: 1;
        transform: translate(-6px, -6px);
    }

    100% {
        opacity: 1;
        transform: translate(-6px, -6px);
    }
}

.arrowCta:before {
    top: 0;
    left: 0;
    border-bottom: 3px solid white;
    border-right: 3px solid white;
    animation: top-arrow 1.65s infinite;
}

@keyframes top-arrow {
    0% {
        transform: translate(-6px, -6px);
    }

    35% {
        transform: translate(0, 0);
    }

    90% {
        opacity: 1;
        transform: translate(0, 0);
    }

    100% {
        opacity: 1;
        transform: translate(0, 0);
    }
}

@media screen and (max-width: 800px) {

    .home-hero-section {
        text-align: center;
    }

    .home-hero-title {
        font-size: 3.5rem;
        text-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    }

    .home-hero-link {
        font-size: 16px;
        text-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    }

    .arrowCta {
        position: absolute;
        left: 50px;
    }

    .cl-section {
        z-index: 99999 !important;
    }

    .hero-image {
        width: 100% !important;
        object-position: right !important;
        position: absolute !important;
        left: 0 !important;
    }
}

@media screen and (max-width: 1300px) {
    .home-hero-section {
        padding: 50px;
    }
}