@import url('https://fonts.googleapis.com/css2?family=Hanken+Grotesk:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

.f-background {
    width: 100%;
    position: relative;
}

.logo-part {
    position: absolute;
    bottom: 0;
    left: 0;
    opacity: .1;
    z-index: -1;
}

.f-section {
    max-width: 1200px;
    margin: auto;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-end;
    justify-content: space-between;
    padding: 50px 0 75px;
}

.f-section .f-logo-section {
    width: 33%;
    margin: auto;
    height: 150px;
}

.f-section .f-logo-section img {
    width: 50%;
}

.f-section .f-logo-section p {
    margin-top: 25px;
    font-size: 18px;
}

.f-section .f-contact {
    width: 33%;
    margin: auto;
    height: 150px;
}

.f-section .f-contact h3 {
    font-weight: 900;
    text-transform: uppercase;
    margin-bottom: 25px;
    font-size: 22px;
    letter-spacing: 1px;
}

.f-section .f-contact p {
    font-size: 18px;
    margin: 10px 0;
    font-weight: 300;
}

.f-section .f-socials {
    width: 33%;
    margin: auto;
    height: 150px;
}

.f-section .f-socials h3 {
    font-weight: 900;
    text-transform: uppercase;
    margin-bottom: 25px;
    font-size: 22px;
    letter-spacing: 1px;
}

.f-section .f-socials .f-icons {
    color: #1f1f1f;
    height: 45px;
    width: 45px;
    padding: 10px;
    margin-right: 15px;
    background-color: #99cc00;
    border-radius: 10px;
    transition: .25s;
}

.f-section .f-socials .f-icons:hover {
    background-color: #1f1f1f;
    fill: #99cc00;
}

.f-subfooter{
    margin: auto;
    max-width: 1200px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 0;
}

.f-subfooter a{
    text-decoration: none;
    font-weight: 700;
    transition: .25s;
}

.f-subfooter a:hover{
    color: #99cc00;
}

@media screen and (max-width: 800px) {
    .f-section {
        width: 85%;
        margin: auto;
    }

    .f-section .f-logo-section{
        display: block;
        width: 100%;
        margin: auto;
    }

    .f-section .f-logo-section img{
        margin: auto;
    }

    .f-section .f-contact{
        display: block;
        width: 100%;
        margin: 75px auto;
    }

    .f-section .f-socials{
        display: block;
        width: 100%;
    }

    .f-subfooter{
        width: 85%;
    }

    .f-subfooter p{
        font-size: 14px;
    }
}

@media screen and (min-width: 800px) and (max-width: 1250px) {
    .f-section {
        margin: 175px 50px 0;
    }

    .f-subfooter{
        margin: 0 50px;
    }
}