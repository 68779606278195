.q-section {
    max-width: 1200px;
    width: 100%;
    margin: 175px auto;
}

.q-section h1 {
    font-weight: 900;
    font-size: 36px;
    position: relative;
    margin-bottom: 50px;
}

.q-section h1::after {
    content: '';
    position: absolute;
    width: 100px;
    height: 5px;
    background: #99cc00;
    bottom: -20px;
    left: 0;
    border-radius: 10px;
}

.q-div {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    position: relative;
    top: 50px;
    gap: 25px;
}

.q-accordion-section {
    flex-basis: 50%;
}

.q-img-section {
    flex-basis: 50%;
}

.q-img-section img {
    width: 100%;
    border-radius: 10px;
}

.tab {
    position: relative;
    margin-bottom: 10px;
    width: 100%;
    color: #fff;
    overflow: hidden;
}

.tab>input {
    position: absolute;
    opacity: 0;
    z-index: -1;
}

.tab>label {
    position: relative;
    display: block;
    padding: 0 0 0 1em;
    background: #1f1f1f;
    line-height: 3;
    cursor: pointer;
    border-radius: 8px 8px 0 0;
    font-size: 20px;
    color: rgb(240, 240, 240);
}

.blue .tab>label {
    background: #1f1f1f;
}

.tab-content {
    max-height: 0;
    overflow: hidden;
    background: #1f1f1f;
    -webkit-transition: max-height .35s;
    -o-transition: max-height .35s;
    transition: max-height .35s;
}

.blue .tab-content {
    background: #1f1f1f;
}

.tab-content p {
    margin: 1em;
    font-size: 17px;
    line-height: 26px;
    color: rgb(240, 240, 240);
}

/* :checked */
.tab>input:checked~.tab-content {
    max-height: 15em;
}

/* Icon */
.tab>label::after {
    position: absolute;
    right: 0;
    top: 0;
    display: block;
    width: 3em;
    height: 3em;
    line-height: 3;
    text-align: center;
    -webkit-transition: all .35s;
    -o-transition: all .35s;
    transition: all .35s;
}

.tab>input[type=checkbox]+label::after {
    content: "+";
}

.tab>input[type=radio]+label::after {
    content: "\25BC";
}

.tab>input[type=checkbox]:checked+label::after {
    transform: rotate(315deg);
}

.tab>input[type=radio]:checked+label::after {
    transform: rotateX(180deg);
}

@media screen and (max-width: 800px) {
    .q-section {
        display: block;
        margin: 125px auto;
        width: 85%;
    }

    .q-div {
        width: 100%;
        display: block;
    }

    .q-accordion-section {
        width: 100%;
    }

    .q-img-section {
        position: relative;
        top: 25px;
    }
}


@media screen and (min-width: 800px) and (max-width: 1250px) {
    .q-section {
        padding: 0 50px;
    }

    .eg-box-section {
        width: 100%;
    }
}