.eg-section {
    max-width: 1200px;
    width: 100%;
    margin: 175px auto;
}

.eg-section h1 {
    font-weight: 900;
    font-size: 36px;
    position: relative;
    margin-bottom: 50px;
}

.eg-section h1::after {
    content: '';
    position: absolute;
    width: 100px;
    height: 5px;
    background: #99cc00;
    bottom: -20px;
    left: 0;
    border-radius: 10px;
}

.eg-box-section {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    position: relative;
    top: 50px
}

.eg-boxes{
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex: 1;
    gap: 10px;
}

.eg-top{
    margin-top: 50px;
}

.eg-box-text {
    background-color: #99cc00;
    height: 150px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    border-radius: 10px;
    box-shadow: 0 0 10px #6e920048;
    flex-basis: 50%;
}

.eg-box-text h3 {
    font-size: 46px;
    font-weight: 900;
    color: white;
    text-shadow: 0 0 7px #6e9200;
}

.eg-box-text p {
    color: white;
    font-weight: 300;
}

.eg-box-image {
    flex: 1;
    height: 150px;
}

.eg-box-image img {
    width: 100%;
    height: 150px;
    object-fit: cover;
    object-position: bottom;
    border-radius: 10px;
    box-shadow: 0 0 10px #6e920048;
}

@media screen and (max-width: 800px) {
    .eg-section {
        display: block;
        width: 85%;
    }

    .eg-boxes{
        display: flex;
        flex-basis: 100%;
        margin: 50px 0;
    }

    .eg-box-section{
        display: block;
    }

    .eg-box-text{
        flex-basis: 50%;
    }

    .eg-box-image{
        display: block;
        flex-basis: 100%;
    }
}

@media screen and (min-width: 800px) and (max-width: 1250px) {
    .eg-section {
        padding: 0 50px;
    }

    .eg-box-section {
        width: 100%;
    }
}